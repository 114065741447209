import { Plan } from './Plan'

export enum SubscriptionStatus {
  TRIALING = 'TRIALING',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  CANCELING = 'CANCELING',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}

export interface Subscription {
  id: string
  price: number
  status: SubscriptionStatus
  startDate: Date
  endDate: Date
  freeTrialEndDate: Date | undefined
  nextPaymentDate: Date
  externalReference: string
  assistants: number
  branches: number
  services: number
  clients: number
  plan: Plan
}
