// Company
export const EXCEPTION_COMPANY_NAME_ALREADY_EXIST = 'Company name already exist.'
export const EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD = 'Company name is a reserved keyword.'

// Branch
export const EXCEPTION_BRANCH_NAME_ALREADY_EXIST = 'Branch name already exist.'
export const EXCEPTION_BRANCH_ADDRESS_ALREADY_EXIST = 'Branch address already exist.'

// Assistant
export const EXCEPTION_ASSISTANT_LIMIT_REACHED = 'Assistant limit reached. Please upgrade your subscription to create more assistants.'
export const EXCEPTION_ASSISTANT_NAME_ALREADY_EXIST = 'Assistant name already exist.'

// Service
export const EXCEPTION_SERVICE_NAME_ALREADY_EXIST = 'Service name already exist.'

// User
export const EXCEPTION_USER_NOT_FOUND = 'User not found.'
export const EXCEPTION_USER_COMPANY_ALREADY_EXIST = 'User company already exist.'
export const EXCEPTION_USER_BRANCH_ALREADY_EXIST = 'User branch already exist.'
export const EXCEPTION_USER_ASSISTANT_ALREADY_EXIST = 'User assistant already exist.'

// Subscription
export const EXCEPTION_SUBSCRIPTION_DOWNGRADE = 'You have more assistants than this subscription allows. Please remove assistants to proceed.'
export const EXCEPTION_SUBSCRIPTION_REQUIRED = 'An active subscription is required to perform this action'

// Booking
export const EXCEPTION_BOOKING_NOT_CANCELABLE = 'The booking is not cancelable'
export const EXCEPTION_BOOKING_NOT_AVAILABLE = 'An error occurred creating the booking. The provided date is not available.'




