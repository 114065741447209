// project imports
import { isFuture } from './dates'
import { Plan } from '../types/Plan'
import { Subscription, SubscriptionStatus } from '../types/Subscription'

// ========================|| UTILS - SUBSCRIPTION ||======================== //

export const getSubTotal = (plan: Plan, assistants: number): number => {
  return Math.round(plan.price + plan.additional * (assistants - 1))
}

export const getTotal = (plan: Plan, assistants: number): number => {
  return Math.round(getSubTotal(plan, assistants) + getTaxes(plan, assistants))
}

export const getTaxes = (plan: Plan, assistants: number): number => {
  return Math.round(getSubTotal(plan, assistants) * plan.taxes)
}

export const isActive = (subscription: Subscription | undefined): boolean => {
  // There is no active subscription
  if (!subscription) return false;

  if (subscription.status === SubscriptionStatus.ACTIVE) return true;
  if (subscription.status === SubscriptionStatus.CANCELING) return true;
  if (subscription.status === SubscriptionStatus.PAUSED) return false;
  if (subscription.status === SubscriptionStatus.EXPIRED) return false;
  if (subscription.status === SubscriptionStatus.CANCELED) return false;

  // Subscription is TRIALING or PENDING but has no free trial
  if (!subscription.freeTrialEndDate) return false;

  // Subscription is TRIALING or PENDING, and there are free trial days remaining
  return isFuture(subscription.freeTrialEndDate)
}

export const isInactive = (subscription: Subscription | undefined): boolean => {
  return !isActive(subscription)
}