import { useEffect, useState } from 'react'

// project imports
import useAuth from '../../context/auth/useAuth'
import PaymentApi from '../../api/payment/PaymentApi'
import { Payment } from '../../types/Payment'
import { ApiError } from '../../types/ApiError'
import { DEFAULT_PAGE, Page, Pageable } from '../../types/Page'

// apis
const paymentApi = new PaymentApi()

// ========================|| HOOK - PAYMENT ||======================== //

const usePayments = (companyId: string) => {
  const { auth } = useAuth()
  const [payments, setPayments] = useState<Page<Payment>>(DEFAULT_PAGE)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ApiError>()
  const [pageable, setPageable] = useState<Pageable>({
    page: 0,
    size: 5,
    sort: 'debitDate,desc',
  })

  const findAll = () => {
    auth!.getIdToken().then(token => {
      paymentApi
        .findAll(token, companyId, pageable)
        .then(data => setPayments(data))
        .catch(error => setError(error))
        .finally(() => setLoading(false))
    })
  }

  useEffect(findAll, [companyId, pageable])

  return { loading, error, payments, setPayments, pageable, setPageable }
}

export default usePayments
