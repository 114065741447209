import { useContext } from 'react'

// material-ui
import { Grid2 as Grid } from '@mui/material'

// project imports
import SubscriptionContext from '../../../../context/subscription/SubscriptionContext'
import PaymentMethodCard from '../PaymentMethodCard/PaymentMethodCard'
import SubscriptionUpdateCard from '../SubscriptionUpdateCard/SubscriptionUpdateCard'
import SubscriptionFreeTrialAlert from '../SubscriptionFreeTrialAlert/SubscriptionFreeTrialAlert'
import { SubscriptionStatus } from '../../../../types/Subscription'

// ========================|| SUBSCRIPTION - CARD ||======================== //

export default function SubscriptionCard() {
  const { subscription } = useContext(SubscriptionContext)

  return (
    <Grid container spacing={3}>
      {subscription.status === SubscriptionStatus.PENDING && subscription.freeTrialEndDate && (
        <Grid size={12}>
          <SubscriptionFreeTrialAlert freeTrialEndDate={subscription.endDate} />
        </Grid>
      )}
      <Grid size={12}>
        <SubscriptionUpdateCard />
      </Grid>
      <Grid size={12}>
        <PaymentMethodCard />
      </Grid>
    </Grid>
  )
}
