import React, { useContext, useState } from 'react'

// material-ui
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

// third-party
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// project imports
import CompanyApi from '../../../../api/company/CompanyApi'
import useAuth from '../../../../context/auth/useAuth'
import UserCompanyContext from '../../../../context/usercompany/UserCompanyContext'
import OptionalSubscriptionContext from '../../../../context/subscription/OptionalSubscriptionContext'
import GreenSwitch from '../../../../ui-components/extended/switch/GreenSwitch'
import { setSnackbar } from '../../../../store/snackbar/reducer'
import { isInactive } from '../../../../utils/subscription'
import { Company, CompanyUpdateForm } from '../../../../types/Company'
import { ApiError } from '../../../../types/ApiError'
import { EXCEPTION_COMPANY_NAME_ALREADY_EXIST, EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD } from '../../../../api/exceptions/exceptions'

// ========================|| PROFILE - FORM ||======================== //

const companyApi = new CompanyApi()

export default function ProfileForm() {
  // hooks
  const theme = useTheme()
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { userCompany, setUserCompany } = useContext(UserCompanyContext)
  const { subscription } = useContext(OptionalSubscriptionContext)
  const dispatch = useDispatch()

  // react-hook-form
  const { control, formState, handleSubmit, getValues, reset, setError } = useForm<CompanyUpdateForm>({
    defaultValues: {
      name: userCompany.name,
      displayName: userCompany.displayName,
      active: userCompany.active,
    },
  })

  // useEffect(() => {
  //   setValue('name', userCompany.name)
  //   setValue('displayName', userCompany.displayName)
  //   setValue('active', userCompany.active)
  // }, [userCompany])

  // state
  const [loading, setLoading] = useState(false)

  const handleSuccess = (company: Company) => {
    setUserCompany({ ...company, role: userCompany.role })

    reset(company)

    dispatch(
      setSnackbar({
        message: t('Company updated successfully'),
        severity: 'success',
        open: true,
      }),
    )
  }

  const handleError = (error: ApiError) => {
    if (error.message === EXCEPTION_COMPANY_NAME_ALREADY_EXIST) {
      setError('name', { message: EXCEPTION_COMPANY_NAME_ALREADY_EXIST })
      return
    }

    if (error.message === EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD) {
      setError('name', { message: EXCEPTION_COMPANY_NAME_RESERVED_KEYWORD })
      return
    }

    dispatch(
      setSnackbar({
        message: t('An unexpected error occurred while updating company'),
        severity: 'error',
        open: true,
      }),
    )
  }

  const handleSubmitForm: SubmitHandler<CompanyUpdateForm> = form => {
    setLoading(true)
    auth?.getIdToken().then(token => {
      companyApi
        .update(token, userCompany.id, form)
        .then(handleSuccess)
        .catch(handleError)
        .finally(() => setLoading(false))
    })
  }

  const buildURL = (url: string) => {
    return `${process.env.REACT_APP_HOST}/${url.toLowerCase()}`
  }

  const handleCopyURL = () => {
    navigator.clipboard.writeText(buildURL(getValues('name'))).then(() => {
      dispatch(
        setSnackbar({
          message: t('Copied to clipboard'),
          severity: 'success',
          open: true,
        }),
      )
    })
  }

  return (
    <Box component='form' onSubmit={handleSubmit(handleSubmitForm)}>
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Controller
            name='displayName'
            control={control}
            rules={{
              required: 'This field is required.',
              minLength: { value: 2, message: 'Name is too short.' },
              maxLength: { value: 100, message: 'Name is too long.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='displayName'
                label={t('Name')}
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                disabled={isInactive(subscription)}
                helperText={error ? t(error.message as string) : null}
                slotProps={{
                  input: {
                    autoComplete: 'off'
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Controller
            name='name'
            control={control}
            rules={{
              required: 'This field is required.',
              pattern: {
                value: /^[a-z0-9]+$/,
                message: 'ID can only contain lowercase characters or numbers.',
              },
              minLength: { value: 2, message: 'ID is too short.' },
              maxLength: { value: 100, message: 'ID is too long.' },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='name'
                label={t('ID')}
                fullWidth
                value={value}
                onChange={event => onChange(event.target.value.toLowerCase())}
                error={!!error}
                disabled={isInactive(subscription)}
                helperText={error ? t(error.message as string) : value ? buildURL(value) : null}
                slotProps={{
                  formHelperText: {
                    sx: { wordWrap: 'break-word' }
                  },
                  input: {
                    autoComplete: 'off',
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={handleCopyURL} color='primary'>
                          <ContentCopyTwoToneIcon fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <Controller
            name='active'
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl component='fieldset' variant='standard' fullWidth>
                <FormLabel component='legend'>
                  <Typography variant='subtitle1'>{t('Company status')}</Typography>
                </FormLabel>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <FormGroup>
                    <FormControlLabel
                      disabled={isInactive(subscription)}
                      control={<GreenSwitch id='active' value={value} checked={value} onChange={onChange} />}
                      label={t('Allow users to find your company')}
                    />
                    {isInactive(subscription) && <FormHelperText>{t('An active subscription is required to enable the company.')}</FormHelperText>}
                  </FormGroup>
                  <Typography
                    variant='h6'
                    display='flex'
                    alignItems='center'
                    sx={{ color: value ? theme.palette.success.dark : theme.palette.error.main }}
                  >
                    <FiberManualRecordIcon
                      sx={{
                        width: '12px',
                        height: '12px',
                        mr: '4px',
                        color: value ? theme.palette.success.dark : theme.palette.error.main,
                      }}
                    />
                    {value ? t('Active') : t('Inactive')}
                  </Typography>
                </Box>
              </FormControl>
            )}
          />
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Grid size={12}>
          <LoadingButton type='submit' variant='contained' disabled={!formState.isDirty} loading={loading}>
            {t('Save changes')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  )
}
