// material-ui
import { Chip } from '@mui/material'

// third-party
import { useTranslation } from 'react-i18next'

// project imports
import { SubscriptionStatus } from '../../../../types/Subscription'

// ========================|| SUBSCRIPTION - STATUS CHIP ||======================== //

interface Props {
  status: SubscriptionStatus
}

export default function SubscriptionStatusChip({ status }: Props) {
  // hooks
  const { t } = useTranslation()

  const statusColor = () => {
    switch (status) {
      case SubscriptionStatus.TRIALING:
        return 'secondary'
      case SubscriptionStatus.PENDING:
        return 'warning'
      case SubscriptionStatus.ACTIVE:
        return 'success'
      case SubscriptionStatus.CANCELING:
        return 'primary'
      case SubscriptionStatus.CANCELED:
        return 'error'
      case SubscriptionStatus.EXPIRED:
        return 'error'
      case SubscriptionStatus.PAUSED:
        return 'warning'
    }
  }

  const statusLabel = () => {
    switch (status) {
      case SubscriptionStatus.TRIALING:
        return 'Free Trial'
      case SubscriptionStatus.PENDING:
        return 'Pending'
      case SubscriptionStatus.ACTIVE:
        return 'Active'
      case SubscriptionStatus.CANCELING:
        return 'Canceling'
      case SubscriptionStatus.CANCELED:
        return 'Canceled'
      case SubscriptionStatus.EXPIRED:
        return 'Expired'
      case SubscriptionStatus.PAUSED:
        return 'Paused'
    }
  }

  return <Chip color={statusColor()} label={t(statusLabel())} size='small' />
}