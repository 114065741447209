// material-ui
import { Alert } from '@mui/material'

// third-party
import { Trans, useTranslation } from 'react-i18next'

// project imports
import { isPast, remainingDays } from '../../../../utils/dates'

// ========================|| SUBSCRIPTION - FREE TRIAL ALERT ||======================== //

interface Props {
  freeTrialEndDate: Date
}

export default function SubscriptionFreeTrialAlert({ freeTrialEndDate }: Props) {
  // hooks
  const { t } = useTranslation()
  const freeTrialRemainingDays = remainingDays(freeTrialEndDate)

  if (isPast(freeTrialEndDate)) {
    return (
      <Alert variant='outlined' severity='warning' sx={{ display: 'flex', alignItems: 'center' }}>
        {t('Your free trial expired. Purchase the subscription to continue using the service.')}
      </Alert>
    )
  }

  return (
    <Alert variant='outlined' severity='warning' sx={{ display: 'flex', alignItems: 'center' }}>
      <Trans i18nKey='freeTrialAlert_remainingDays' count={freeTrialRemainingDays}>
        Your free trial will expire in <strong title={`${freeTrialRemainingDays}`}>days</strong>. To continue using the service after the trial
        period, you must purchase the subscription.
      </Trans>
    </Alert>
  )
}
