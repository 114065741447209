import dayjs from 'dayjs'

export const isFuture = (date: Date) => dayjs(date).isAfter(dayjs())

export const isPast = (date: Date) => dayjs(date).isBefore(dayjs())

export const remainingDays = (date: Date | undefined): number => {
  if (!date) return 0

  if (isPast(date)) {
    return 0
  }

  return dayjs(date).diff(new Date(), 'days')
}
